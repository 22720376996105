import { RiLock2Fill } from 'react-icons/ri'

const LockIcon = ({lockIconSize}: {lockIconSize: number}) =>
  <div style={{marginTop: -1}}>
    <RiLock2Fill
      size={lockIconSize}
    />
  </div>

const LockTag = ({children, lockIconSize}: {children: any; lockIconSize: number}) =>
  <div className="flex w-max px-2 py-1 mb-2 rounded-md bg-olive text-white items-center">
    <LockIcon lockIconSize={lockIconSize} />
    <div className="ml-1 tracking-widest font-soehne-kraftig uppercase" style={{fontSize: 12, fontWeight: 'bold'}}>
      {children}
    </div>
  </div>

export default function LockDisplay ({
  subscriptionRequired,
  isStandard,
  tag,
  lockIconSize,
}: {
  subscriptionRequired: string
  isStandard: boolean
  tag: string
  lockIconSize: number
}) {
  return (
    <>
      {
        subscriptionRequired === 'free'
        ? <></>
        : (
          isStandard
          ? <LockTag lockIconSize={lockIconSize}>{tag}</LockTag>
          : <LockTag lockIconSize={lockIconSize}>{tag}</LockTag>
        )
      }
    </>
  )
}
