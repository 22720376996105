import { useEffect, useState } from 'react'
import useCookie from "../hooks/useCookie";


const EmailModal = () => {
    const [visible, setVisible] = useState(false);
    const [cookie, updateCookie] = useCookie("mailchimp", "");

    const afterSubmit = () => {
        updateCookie("true", 90);
        setVisible(false)
    }

    const closeModal = () => {
        updateCookie("true", 60);
        setVisible(false)
    }

    useEffect(() => {
        if(cookie == ""){
            setVisible(true)
        }
    }, [])

    return(
        <div className='modal-container'>
            <div className={`modal fade show ${visible ? 'in' : 'hidden'}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className='flex'>
                            <div className='sub-image'>
                                <img src="/images/mailchimp.jpg"></img>
                            </div>
                            <div className='email-form'>
                                <button type="button" className="modal-close-btn btn btn-secondary" onClick={() => (closeModal())}><span aria-hidden="true">&times;</span></button>
                                <img src="/images/mailchimp-icon.png" className='email-icon'></img>
                                <p className='small'>Welcome!</p>
                                <div className="modal-header">
                                    <h3 className="modal-title">GET EXCLUSIVE ACCESS</h3>
                                </div>
                                <div className="modal-body">
                                    <p>Sign up for early product drops, promotions, digital events, content and more. Only for email subscribers.</p>
                                </div>
                                
                                <div>
                                    <form action="https://beyondthepines.us7.list-manage.com/subscribe/post?u=53e7a73708dbdbc58064f2b19&amp;id=74141a43b3" method="post" target="_blank" onSubmit={() => (afterSubmit())}>
                                        <label htmlFor="name" className='small'>EMAIL ADDRESS</label>
                                        <input id="name" name="EMAIL" placeholder='EMAIL ADDRESS' type="text" autoComplete="name" required />
                                        <div className='hide-tag'><input type="text" name="b_53e7a73708dbdbc58064f2b19_74141a43b3" tabIndex="-1" defaultValue="" /></div>
                                        <button type="submit">GET EXCLUSIVE ACCESS</button>
                                    </form>
                                    <button onClick={() => (closeModal())}><p className='small underline'>NO THANKS</p></button>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailModal;