import React, { useState, useEffect } from 'react'
import { styled } from 'styled-components'
import NextImage from 'next/image'
import { usePalette } from 'color-thief-react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, Navigation } from 'swiper'

import logo from '../assets/btp-logo.png'

import { useApiCall } from '../hooks/useApiCall'
import { useGetCensored } from '../hooks/useGetCensored'

const ImageCarousel = () => {
  const drops = useGetCensored({ limit: 3 })

  const blogData = useApiCall({
    contentType: 'blogPost',
    order: '-fields.datePublished',
    limit: 3,
    options: { subscriptionRequired: false }
  })

  const featuredImageData = useApiCall({ contentType: 'carouselImages' })
  if (!featuredImageData.length || !drops.length) return null

  return (
    <Swiper
      className={'home-swiper'}
      modules={[Autoplay, Pagination, Navigation]}
      loop={true}
      breakpoints={{
        576: {
          // width: 576,
          slidesPerView: 1,
        },
        768: {
          // width: 768,
          slidesPerView: 2,
        },
      }}
      pagination={{
        clickable: true
      }}
      autoplay={{
        delay: 4200,
        disableOnInteraction: false
      }}
    >
      {blogData.map((blog) => (
        <SwiperSlide key={blog.title} style={{ position: 'relative', width: '100vw' }}>
          <Link href={`/blogs/${blog.blogUrl}`} passHref>
            <a className="flex relative w-full">
              <Drop key={blog.title} title={blog.title} image={blog.thumbnail} />
            </a>
          </Link>
        </SwiperSlide>
      ))}
      {drops.map((drop) => (
        <SwiperSlide key={drop.title} style={{ position: 'relative', width: '100vw' }}>
          <Link href={'/subscribe'} passHref>
            <a className="flex relative w-full">
              <Drop key={drop.title} title={drop.title} image={drop.image} />
            </a>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default ImageCarousel

const Drop = ({ image, title }) => {
  const router = useRouter()

  const { data: palette = [] } = usePalette(image, 2, 'rgbString', { crossOrigin: true })

  return (
    <Container onClick={() => router.push(`/subscribe`)} image={image} palette={palette}>
      <Content>
        <H3 className="cursor-pointer text-white text-3xl font-soehne-xleight text-center z-0 px-10 mb-5">
          {title}
        </H3>
        <NextImage src={logo} width={120} height={50} className="logo invert" />
      </Content>
      <ImageComponent image={image} palette={palette} />
      
    </Container>
  )
}

const ImageComponent = ({ image, palette }) => {
  const [isPortrait, setIsPortrait] = useState(false);
  
  useEffect(() => {
    if (image) {
      const img = new Image();
      img.src = image;

      img.onload = () => {
        const aspectRatio = img.width / img.height;
        setIsPortrait(aspectRatio < 1);
      };
    }
  }, [image]);

  return <ImageHolder image={image} palette={palette} isPortrait={isPortrait} />;
};


const ImageHolder = styled.div`
  width: ${(props) => (props.isPortrait ? '50%' : '100%')};
  background-position: center;
  min-height: 50vh;
  position: relative;
  z-index: 5;
  background-size: cover;
  background-image: linear-gradient(270deg, transparent 40%, ${(props) => props.palette[0]} 100%),
    url(${(props) => props.image});

  @media only screen and (max-width: 600px) {
    min-height: 40vh;
    width: 50%;
  }
`

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 8px #f0f0f0 solid;
  border-radius: 8px;
  background: linear-gradient(
    270deg,
    ${(props) => props.palette[0]} 50%,
    ${(props) => props.palette[1]} 100%
  );
  cursor: pointer;
  &:nth-child(even) {
    background: linear-gradient(
      90deg,
      ${(props) => props.palette[0]} 50%,
      ${(props) => props.palette[1]} 100%
    );
    flex-direction: row-reverse;
    ${ImageHolder} {
      background-image: linear-gradient(90deg, transparent 50%, ${(props) => props.palette[0]} 100%),
        url(${(props) => props.image});
    }
  }

  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.01);
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

const Content = styled.div`
  text-align: center;
  margin: 15px auto;
  padding: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 600px) {
    width: 50%;
  }
`

const H3 = styled.h3`
  @media only screen and (max-width: 600px) {
    font-size: 1em;
    line-height: 1em;
    margin-bottom: 12px;
    padding: 0;
  }
`
