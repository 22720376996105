import { useState, useEffect } from 'react';

export const useApiCall = ({contentType, order, options = {}, limit = 200}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`/api/getEntries`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            content_type: contentType,
            order: order,
            options: options,
            limit: limit,
          }),
        })
        const json = await response.json();
        setData(json)
      } catch (e) {
          console.error(e);
      }
    };
    fetchData();
  }, []);

  return data;
}


