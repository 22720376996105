import React from 'react'
import { styled } from 'styled-components'
import { useRecoilValue } from 'recoil'
import { subDataState } from '@/util/states'

import { useApiCall } from 'src/hooks/useApiCall'
import { Drop } from './Drop'
import { Post } from './Post'

let STANDARD = '5U3SNl2Kab22pPlQVlTL7u'
let BEYOND = '7IQbJLyA8k9Y5RtOXKalQI'

export const Dashboard = () => {
  const subData = useRecoilValue(subDataState)
  const tier = subData.tier === 'Standard' ? STANDARD : BEYOND

  const drops = useApiCall({
    contentType: 'drop',
    order: '-fields.dropDate',
    limit: 6,
    options: { subscriptionTier: tier }
  })

  const bts = useApiCall({
    contentType: 'blogPost',
    order: '-fields.datePublished',
    limit: 4,
    options: { subscriptionRequired: true, match: 'Behind the scenes', subscriptionTier: tier }
  })

  return (
    <>
      <HeaderText>Latest Drops</HeaderText>
      <DropList drops={drops} />
      {bts.length > 0 && <HeaderText>Latest Behind the Scenes</HeaderText>}
      <BtsList bts={bts} />
    </>
  )
}

const DropList = ({ drops }) => (
  <Container>
    {drops.map((d) => (
      <Drop key={d.sys.id} drop={d} />
    ))}
  </Container>
)

const BtsList = ({ bts }) => (
  <Container>
    {bts.map((post) => (
      <Post key={post?.id} post={post} />
    ))}
  </Container>
)

const HeaderText = styled.h1`
  color: black;
  font-size: 1.5em;
  font-family: 'Soehne-Leicht';
  text-align: center;
  margin: 20px 0;
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`
const Highlight = styled.span`
  background: #d2fc33;
`

const Vol2Cover = styled.img`
  width: 80%;
  display: block;
  margin: 0 auto;
`
