import React, {useEffect} from 'react'
import { styled } from 'styled-components';
import Image from 'next/image'
import { usePalette } from 'color-thief-react'


import { useRouter } from 'next/router'
import { urlParser } from '@/util/imgHelpers'
import logo from '../../assets/btp-logo.png'

export const Drop = ({ drop }) => {
  const router = useRouter()
  const { fields, sys } = drop
  const { cover, title, issue, dropDate } = fields

  const issueCover = urlParser(cover?.fields?.file?.url)
  const dropUrl = title.split(' ').join('-')
  const issueUrl = issue?.fields?.issueUrl

  const { data: palette = [] } = usePalette(cover?.fields?.file?.url, 2, 'rgbString', {crossOrigin: true})

  return (
    <Container onClick={() => router.push(`/issues/${issueUrl}/${dropUrl}`)} key={sys.id} image={issueCover} palette={palette}>
      <Content>
        <H3 className="cursor-pointer text-white text-3xl font-soehne-xleight text-center z-0 px-10 mb-5">
          {title}
        </H3>
        <h4 className="cursor-pointer text-white text-lg font-soehne-leight text-center z-0">Released: {new Date(dropDate).toLocaleDateString()}</h4>
        <Image src={logo} width={120} height={50} className="logo invert" />
      </Content>
      <ImageHolder image={issueCover} palette={palette} />
    </Container>
  )
}

const ImageHolder = styled.div`
  width: 50%;
  min-height: 50vh;
  position: relative;
  z-index: 5;
  background-size: cover;
  background-image: linear-gradient(270deg, transparent 40%, ${props => props.palette[0]} 100%), url(${props => props.image});

  @media only screen and (max-width: 600px) {
    min-height: 40vh;
  }
`;


const Container = styled.div`
  position: relative;
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 8px #f0f0f0 solid;
  border-radius: 8px;
  background: linear-gradient(270deg, ${props => props.palette[0]} 50%, ${props => props.palette[1]} 100%);
  cursor: pointer;
  &:nth-child(even){
    background: linear-gradient(90deg, ${props => props.palette[0]} 50%, ${props => props.palette[1]} 100%);
    flex-direction: row-reverse;
    ${ImageHolder} {
      background-image: linear-gradient(90deg, transparent 50%, ${props => props.palette[0]} 100%), url(${props => props.image}); 
    }
  }
  
  transition: transform .2s ease-in-out; 

  &:hover{
    transform: scale(1.01); 
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
  
`;

const Content = styled.div`
  text-align: center;
  margin: 15px auto;
  padding: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  


  @media only screen and (max-width: 600px) {
    width: 50%;
  }
  
`;

const H3 = styled.h3`
@media only screen and (max-width: 600px) {
    font-size: 1.4em;
    line-height: 1em;
    margin-bottom: 5px;
    padding: 0;
  }
`;