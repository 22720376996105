import FadeImg from './fadeImg'

export default function HomePageImage ({
  isMobile,
  src,
  alt,
  className
}: {
  isMobile: boolean
  src: string
  alt: string
  className: string
}) {

  return (
    <FadeImg
      style={{
        width: isMobile ? '' : 400,
        objectFit: 'contain',
      }}
      src={src}
      className={className}
      alt={alt}
    />
  )
}
