import React, { useEffect } from 'react'
import { styled } from 'styled-components'
import Image from 'next/image'

import { useRouter } from 'next/router'

export const Post = ({ post }) => {
  const router = useRouter()
  const {thumbnail, title, author, subtitle, date, blogUrl} = post
  return (
    <Container onClick={() => router.push(`/blogs/${blogUrl}`)}>
      <ImageContainer>
        <Image alt="" className="object-cover z-[-1]" src={thumbnail} layout="fill" />
      </ImageContainer>
      
      <h2>{title}</h2>
    </Container>
  )
}

const Container = styled.div`
  width: 300px;
  position: relative;
  cursor: pointer;
`
const ImageContainer = styled.div`
  position: relative;
  width: 300px;
  min-height: 60vh;
`