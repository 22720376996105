import { useState, useEffect } from 'react';

export const useGetCensored = ({limit = 100}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`/api/getCensored`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            limit: limit,
          }),
        })
        const json = await response.json();
        setData(json)
      } catch (e) {
          console.error(e);
      }
    };
    fetchData();
  }, []);

  return data;
}


