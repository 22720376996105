import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { times } from 'lodash'
import Link from 'next/link'
import InfiniteScroll from 'react-infinite-scroll-component'
import Head from 'next/head'
import ScrollableAnchor from "react-scrollable-anchor"

import { ISubData } from '@/util/types'
import useIsMobile from '@/util/useIsMobile'
import { subDataState, userInfoState } from '@/util/states'

import LockDisplay from '../components/LockDisplay'
import HomePageImage from '../components/HomePageImage'
import ImageCarousel from '../components/ImageCarousel'
import { useApiCall } from 'src/hooks/useApiCall'
import EmailModal from '../components/emailModal'
import { Dashboard }  from '../components/Dashboard/Dashboard'

export default function Home() {
  
  const subData = useRecoilValue(subDataState)
  
  if (subData === 'pending') return null
  
  const {isSubbed} = subData

  const Blog = () => {
    const blogData = useApiCall({contentType: 'blogPost', order: '-fields.datePublished', options: { subscriptionRequired: false }})
    const isMobile = useIsMobile()

    if (blogData === undefined) return null
    if (isMobile === undefined) return null

    return (
      <div className="w-full">
      <ImageCarousel />
      <div className="dt:max-w-full mx-auto dt:px-8">
        <Blogs blogData={blogData} isMobile={isMobile} subData={subData} />
      </div>
    </div>
    )
  }
    
  return isSubbed ? <Dashboard /> : <Blog />
}

const Blogs = ({
  isMobile,
  blogData,
  subData
}: {
  isMobile: boolean;
  blogData: any;
  subData: ISubData
}) => {
  const [itemCount, setItemCount] = useState(10)
  const [hasMore, setHasMore] = useState<boolean>(true)

  const blogCountArray = times(itemCount < blogData.length ? itemCount : blogData.length, Number)

  const displayMoreBlogs = () => {
    if (itemCount >= 100) {
      setHasMore(false)
      return
    }
    
    setItemCount((prev) => prev + 10)
  }
  
  const getIsPermitted = (index: number) => {
    switch (blogData[index].subscriptionRequired) {
      case 'free': return true
      case 'Standard': 
        // eslint-disable-next-line sonarjs/prefer-single-boolean-return
        if (subData.isSubbed) return true
        else return false
      case 'Beyond':
        // eslint-disable-next-line sonarjs/prefer-single-boolean-return
        if (subData.tier === 'Beyond') return true
        else return false
      default: return true
    }
  }

  const BlogText = ({ index, isMobile }: { index: number, isMobile: boolean }) => {
      
    const isStandard = blogData[index].subscriptionRequired === 'Standard'
    const lockIconSize = 
      isMobile
      ? 12
      : 16

    return (
      <>
        {isMobile ? (
          <div className="blog-preview-text flex flex-col p-5 mb-4">
            <h2 className="cursor-pointer mb-blogText-mob tracking-wider font-soehne-kraftig text-xs">
              <LockDisplay
                subscriptionRequired={blogData[index].subscriptionRequired}
                isStandard={isStandard}
                tag={'SUBSCRIBE'}
                lockIconSize={lockIconSize} />

              {blogData[index].title}
            </h2>
            <hr className="content-divider" />
            <span className="font-soehne-kraftig text-xs text-light">
              {blogData[index].subtitle}
            </span>
            <div>
              <span className="font-soehne-kraftig text-light text-xs tracking-wider">
                {blogData[index].blogType}{' '}
              </span>
              <span className="font-soehne-kraftig text-light text-xs tracking-wider">
                BY: {blogData[index].author.toUpperCase()}
                <br />
                <span className="font-soehne-kraftig">{blogData[index].datePublished}</span>
              </span>
            </div>
          </div>
        ) : (
          <div className="flex flex-col ml-10 pr-5">
            <span className="mb-blogText dt-800:mb-4 text-olive tracking-widest text-10px dt-800:text-9px">
              {blogData[index].blogType}
            </span>
            
            <Link 
              href={
                getIsPermitted(index) 
                ? `blogs/${blogData[index].blogUrl}` 
                : '/subscribe' 
              }
              passHref
            >
              <h2 className="cursor-pointer mb-blogText dt-800:mb-4 font-soehne-leight text-3xl dt-800:text-2xl">
                <LockDisplay
                  subscriptionRequired={blogData[index].subscriptionRequired}
                  isStandard={isStandard}
                  tag={'SUBSCRIBE'}
                  lockIconSize={lockIconSize} />
                {blogData[index].title}
              </h2>
            </Link>
            <hr className="content-divider" />
            <span className="mb-blogText dt-800:mb-4 font-soehne-xleight text-lg dt-800:text-base">
              {blogData[index].subtitle}
            </span>
            <span className="mb-blogText text-olive opacity-50 dt-800:mb-4 font-soehne-kraftig text-xs">
              {blogData[index].author}
            </span>
            <span className="font-soehne-kraftig text-sm dt-800:text-xs">
              {blogData[index].datePublished}
            </span>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <Head>
        <title>Beyond The Pines</title>
        <meta
          name="description"
          content="Beyond The Pines Magazine: breathtaking photography and visual provocation that stimulates thoughts, ideas, & conversations. "
        />
        <meta
          name="keywords"
          content="Beyond The Pines, Beyond The Pines Magazine, Beyond The Pines Mag, magazine, mag, BTP, BTP magazine, BTP mag, "
        />
      </Head>
      <EmailModal />
      <ScrollableAnchor id='blogs-start'>
        <div className={`${isMobile ? '' : 'mt-height-2 mb-20'}`}>
          <h1 className="font-soehne-xleight tracking-wide text-2xl mb-4 ml-5">LATEST</h1>
          <div className='flex'>
            <div className={`${isMobile ? 'w-5/5' : 'w-4/5'}`}>
              <InfiniteScroll
              dataLength={itemCount}
              next={displayMoreBlogs}
              hasMore={hasMore}
              loader={<h4>Loading</h4>}
            >
              {blogCountArray.map((index) => {

                if (!blogData[index]) return null

                return (
                  <div key={index} className="blog-preview">
                    <div className="flex mb:flex-col w-full">
                      <Link passHref href={ 
                        getIsPermitted(index) 
                        ? `blogs/${blogData[index].blogUrl}` 
                        : '/subscribe' 
                      }>                    
                        <div className="flex mb:flex-col">
                          <HomePageImage
                            isMobile={isMobile}
                            src={blogData[index].thumbnail}
                            alt={'Thumbnail for the blog entitled: ' + blogData[index].title}
                            className="
                              blog-preview-image
                              flex-shrink-0
                              dt:w-blogImg
                              cursor-pointer
                              object-cover object-center
                            "
                          />
                          <BlogText index={index} isMobile={isMobile} />
                        </div>
                      </Link>
                    </div>
                    {index !== blogData.length - 1 && !isMobile && <Divider />}
                  </div>
                )
              })}
            </InfiniteScroll>
            </div>
            <div className={`${isMobile ? 'hidden' : 'w-1/5 ad-space'}`}>
            </div>
          </div>
          
        </div>
      </ScrollableAnchor>
    </>
  )
}

const Divider = () => (
  <div
    className="
    mb:my-4 mb:w-blogImg-mob mb:mx-auto 
    dt:my-height-2 dt:w-full
  "
  />
)
